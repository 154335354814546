<template>
  <div>
    <BarraCrud :onFiltrar="filtrar" :botaoNovo="true" :onNovo="onNovo" />
    <empresas-table :filtro="filtro" ref="empresaTableComp" />
  </div>
</template>

<script>
import BarraCrud from "../../components/common/BarraCrud.vue";
import EmpresasTable from "../../components/Empresas/EmpresasTable.vue";
export default {
  components: {
    BarraCrud,
    EmpresasTable,
  },
  props: {
    BarraCrud,
  },
  data() {
    return {
      filtro: "",
     
    };
  },
  methods: {
    filtrar(filtro) {
      this.filtro = filtro;
    },
    onNovo() {
      this.$refs.empresaTableComp.novo();
    },
  },
};
</script>

<style>
</style>