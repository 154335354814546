<template>
  <div>

    <b-form-checkbox  v-model="showInativas" @change="carregar" >Mostrar Inativas</b-form-checkbox>

    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
      show-empty
    >
     <template #empty>
        <div>
          <i class="fas fa-building"></i> Nenhuma
         empresa cadastrada, clique em
          <b-btn variant="primary" size="sm" class="mx-2">
            <i class="fas fa-plus"></i> Empresa</b-btn
          >
          para cadastrar.
        </div>
      </template>
      <template #table-busy class="text-center">
        <b-spinner />
      </template>
      <template #cell(actions)="row">
        <b-button size="sm" variant="warning" @click="editar(row.item)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <b-button size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        >
      </template>

      <template #cell(telefones)="row">
        <div
          v-for="tel in row.item.telefones"
          :key="`telefone_${row.item.telefones.indexOf(tel)}`"
          class="d-block text-truncate"
        >
          {{ tel.telefone }}
        </div>
      </template>

      <template #cell(endereco)="row">
        <div
          v-for="end in row.item.enderecos"
          :key="`endereco_${row.item.enderecos.indexOf(end)}`"
          class="d-block text-truncate"
        >
          {{ end.cidade }} - {{ end.uf }}
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>
    <b-modal
      no-enforce-focus
      id="modal-empresa"
      size="lg"
      title="Cadastrar/Editar Empresa"
      no-fade
      
      content-class="modalDireito"
    >
      <empresas-form
       
        ref="empresaFormComp"
        :empresa_edit="empresa_edit"
        :onSaveDone="onSaveDone"
      />
      <template #modal-footer>
        <b-button size="md" variant="primary" @click="salvar()">
          <i class="fa fa-save"></i> Salvar
        </b-button>
        <b-button size="md" variant="secondary" @click="endEdit()">
          <i class="fa fa-times"></i> Cancelar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import EmpresasLib from "@/libs/EmpresasLib";
import EmpresasForm from "./EmpresaForm.vue";

export default {
  components: {
    EmpresasForm,
  },
  props: {
    filtro: String,
  },
  data() {
    return {
      loading: false,
      lista: [],
      showInativas: true,
      campos: [
        { key: "id", label: "Código", sortable: true },
        { key: "razao_social", label: "Razão Social", sortable: true },
        { key: "fantasia", label: "Fantasia", sortable: true },
        { key: "sigla", label: "Sigla", sortable: true },
        { key: "cnpj", label: "CNPJ", sortable: true },
        { key: "endereco", label: "Cidade", sortable: true },
        { key: "telefones", label: "Telefones", sortable: true },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
      empresa_edit: {
     
      },
    };
  },
  mounted() {
    this.carregar();
  // this.$root.$on('bv::modal::show', ()=>{

  // }) 
  },
  methods: {
    async carregar() {
      this.loading = true;
    
       this.$forceUpdate();
      try {
        this.lista = await EmpresasLib.getEmpresasGrupoAPI(this.showInativas);
        console.log(this.lista)
      } catch (error) {
        console.warn(error);
      }

      this.loading = false;
    },
    limpa() {
      this.empresa_edit = {

      };
    },
    async novo() {
      this.limpa();
      this.$bvModal.show("modal-empresa");
    },
    salvar() {
      this.$refs.empresaFormComp.salvar();
    },
    onSaveDone() {
      this.carregar();
      this.$bvModal.hide("modal-empresa");
    },
    async excluir(empresa) {
      if (empresa) {
        this.$swal
          .fire({
            title: "Você tem certeza que deseja excluir esta empresa?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const res = await EmpresasLib.del({ id: empresa.id });
              if (res && res.success) {
                await this.carregar();
                this.$swal
                  .fire({
                    title: "Empresa excluída com sucesso!",
                    icon: "success",
                    toast: true,
                    timer: 1500,
                    position: "top-end",
                    showConfirmButton: false,
                  })
                  
                    
                 
              } else {
                this.$swal.fire({
                  title: "Erro ao excluir empresa!",
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
            }
          });
      }
    },
    async editar(empresa) {
      this.empresa_edit = Object.assign({}, empresa);
      this.$bvModal.show("modal-empresa");
    },
    endEdit() {
      this.$bvModal.hide("modal-empresa");
      this.limpa();
    },
  },
};
</script>

<style>
</style>