<template>
  <div>
    <small v-if="!!topDescription" class="text-muted">
      {{ topDescription }}
    </small>
    <div class="row">
      <div class="col">
        <h4>Header</h4>
        <ckeditor
          :value="header"
          @input="handleInputHeader"
          :config="editorConfig"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <h4>Footer</h4>
        <ckeditor
          :value="footer"
          @input="handleInputFooter"
          :config="editorConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue";

export default {
  props: {
    header: { type: [String], default: "" },
    footer: { type: [String], default: "" },
    topDescription: {
      type: String,
      default: "* Configuração do topo e do fim dos relatórios da empresa",
    },
  },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editorConfig: {
        //editor: Classic
        extraPlugins: "div, font, justify",
        // stylesSet: [
        //   { name: "flex", element: "div", styles: { "display": "grid", "jusitify-content": 'center', "grid-auto-flow": "column"  } },
        // ],
      },
    };
  },
  mounted() {},

  methods: {
    handleInputHeader(value) {
      this.$emit("header", value);
    },
    handleInputFooter(value) {
      this.$emit("footer", value);
    },
  },
};
</script>

<style></style>
