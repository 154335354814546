<template>
  <div>
    <!-- {{ notafiscal }} -->

    
    <b-alert variant="danger" class="text-center" :show="!empresaNfe">
      Nota fiscal não configurada
    </b-alert>
    <div class="row">
      <div class="col-12 my-4">
       
        <b-checkbox v-model="empresa.configuracoes.exigirEmissaoNFFechamentoCaixa" value="1" switch>Exigir Emissão de Nota para fechar caixa</b-checkbox>
        <hr/>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <label>Regime Tributário</label>
        <b-form-select
          v-model="notafiscal.regimeTributario"
          :disabled="!letEdit"
          :options="regimesTributarios"
        ></b-form-select>
      </div>
      <div class="col-6">
        <label>Regime Tributário E</label>
        <b-form-select
          v-model="notafiscal.regimeTributarioEspecial"
          :disabled="!letEdit"
          :options="regimesTributariosEspeciais"
        ></b-form-select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-sm-6">
        <label class=""></label>
        <b-form-checkbox
          :disabled="!letEdit"
          class="form-control text-center mt-2"
          v-model="notafiscal.simplesNacional"
          switch
          >Simples Nacional</b-form-checkbox
        >
      </div>
      <div class="col-12 col-sm-6">
        <label> Inscrição Municipal</label>
        <b-form-input
          :disabled="!letEdit"
          v-model="notafiscal.inscricaoMunicipal"
        ></b-form-input>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-form-group>
          <label>Endereço</label>
          <b-form-select
            :disabled="!letEdit"
            @input="selectEndereco"
            :options="enderecosDisponiveis"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group>
          <label>Telefone</label>
          <b-form-select
            :disabled="!letEdit"
            @input="selectTelefone"
            :options="telefonesDisponiveis"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>

    <b-tabs class="border p-3">
      <b-tab title="NFSe">
        <div class="container">
          <div class="row">
            <div class="col">
              <!-- {{notafiscal.nfse.ativo}} -->
              <b-form-checkbox
                :disabled="!letEdit"
                v-model="notafiscal.nfse.ativo"
                switch
                class="form-control text-center"
              >
                Ativo</b-form-checkbox
              >
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <label>Ambiente</label>
              <b-form-select
                :disabled="!letEdit"
                :value="
                  notafiscal.nfse
                    ? notafiscal.nfse.config.producao
                      ? 'Produção'
                      : 'Homologação'
                    : ''
                "
                :options="['Produção', 'Homologação']"
              ></b-form-select>
            </div>
            <div class="col-6">
              <label>Modelo de Contrato</label>
              <b-form-select
                v-model="notafiscal.nfse.tipoContrato"
                :disabled="!letEdit"
                :options="tiposContrato"
              ></b-form-select>
            </div>
          </div>

          <!-- <div class="row mt-3">
            <div class="col-6">
              <label>Aliquota</label>
              <money
                :disabled="!letEdit"
                :class="['form-control']"
                v-model="notafiscal.nfse.aliquota"
                v-bind="pct"
              />
            </div>
          </div> -->

          <!-- <div name="Configurar RPS">
            <div class="row mt-3">
              <div class="col ">
                <label class="separator"> Configurar RPS</label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-table
                  small
                  bordered
                  :items="notafiscal.nfse.config.rps.numeracao"
                  :fields="campos"
                  show-empty
                >
                  <template #empty>
                    <div class="text-center text-muted">
                      Nenhuma série cadastrada
                    </div>
                  </template>

                  <template #cell(serie)="{item}">
                    <div>
                      <b-form-input
                        v-model="item.serie"
                        :disabled="item._rowStatus !== 'added' || !letEdit"
                        size="sm"
                      ></b-form-input>
                    </div>
                  </template>
                  <template #cell(actions)="{item}">
                    <div v-if="!item._rowStatus">
                      <b-btn
                        @click="removeNumeracao(item)"
                        :disabled="!letEdit"
                        size="sm"
                        variant="transparent"
                      >
                        <i class="fas fa-trash"></i>
                      </b-btn>
                    </div>
                    <div v-else>
                      <b-btn
                        @click="saveNumeracao(item)"
                        :disabled="!letEdit"
                        variant="transparent"
                      >
                        <i class="fas fa-save"></i>
                      </b-btn>
                    </div>
                  </template>
                  <template #cell(numero)="{item}">
                    <div>
                      <b-form-input
                        v-model="item.numero"
                        :disabled="item._rowStatus !== 'added' || !letEdit"
                        size="sm"
                      ></b-form-input>
                    </div>
                  </template>
                </b-table>
                <b-btn
                  :disabled="!letEdit"
                  @click="addNumeracao"
                  class="ml-auto mt-0 d-block"
                >
                  <i class="fas fa-plus"></i>
                </b-btn>
              </div>
            </div>

            <div class="row">
              <div class="col-3">
                <b-form-group>
                  <label for="">Lote: </label>
                  <b-form-input
                    v-model="notafiscal.nfse.config.rps.lote"
                    :disabled="!letEdit"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div> -->

          <div name="Autenticação Prefeitura">
            <div class="row mt-3">
              <div class="col ">
                <label class="separator"> Autenticação Prefeitura</label>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <b-form-group>
                  <label for="">Login Prefeitura</label>
                  <b-form-input
                    v-model="notafiscal.nfse.config.prefeitura.login"
                    :disabled="!letEdit"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group>
                  <label for="">Senha Prefeitura</label>
                  <b-form-input
                    v-model="notafiscal.nfse.config.prefeitura.senha"
                    :disabled="!letEdit"
                    type="password"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>

          <div name="Autenticação">
            <div class="row mt-3">
              <div class="col ">
                <label class="separator"> Autenticação </label>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="">Certificado</label>
                  <b-form-select
                    :options="certificados"
                    v-model="notafiscal.certificado"
                    :disabled="!letEdit"
                  ></b-form-select>
                  <div v-if="false" class="d-flex justify-content-between px-1">
                    <small class="text-muted"> <b>ID: </b></small>
                    <small class="text-muted"> <b>Vencimento: </b></small>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>

          <div name="Serviços">
            <div class="row mt-3">
              <div class="col ">
                <label class="separator"> Serviços </label>
              </div>
            </div>

            <div
              class="border rounded p-3 mx-1"
              v-for="servico in notafiscal.nfse.servicos"
              :key="notafiscal.nfse.servicos.indexOf(servico)"
            >
              <div class="row">
                <div class="col">
                  <b-form-group>
                    <label>Código</label>
                    <b-form-input
                      placeholder="Código de serviço"
                      v-model="servico.codigo"
                      size="sm"
                      :disabled="!letEdit"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group>
                    <label>Código Tributação</label>
                    <b-form-input
                      placeholder="Código tributação no Município"
                      v-model="servico.codigoTributacao"
                      size="sm"
                      :disabled="!letEdit"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group>
                    <label>CNAE</label>
                    <b-form-input
                      placeholder="Classificação Nacional de Atividades Econômicas"
                      v-model="servico.cnae"
                      size="sm"
                      :disabled="!letEdit"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <b-form-group>
                    <label>Discriminação</label>
                    <b-form-textarea
                      :disabled="!letEdit"
                      placeholder="Detalhamento do serviço prestado."
                      v-model="servico.discriminacao"
                      size="sm"
                    >
                    </b-form-textarea>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col ">
                  <label class="separator mt-1 mb-0"> ISS </label>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-8">
                  <b-form-group>
                    <label>Tipo Tributação </label>
                    <b-form-select
                      :disabled="!letEdit"
                      :options="tiposTributacao"
                      v-model="servico.iss.tipoTributacao"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-2">
                  <b-form-group>
                    <label>Aliquota </label>
                    <money
                      v-model="servico.iss.aliquota"
                      :disabled="!letEdit"
                      class="form-control form-control-sm text-center"
                      v-bind="pct"
                      size="sm"
                    ></money>
                  </b-form-group>
                </div>
              </div>
            </div>

            <b-btn
              :disabled="!letEdit"
              @click="addServico"
              class="ml-auto my-2 d-block"
            >
              <i class="fas fa-plus"></i> Serviço
            </b-btn>
          </div>
        </div>

        <div class="row align-content-end border-top py-2">
          <div class="col d-flex">
            <b-btn
              @click="editar"
              :variant="!letEdit ? 'warning' : 'info'"
              class="mr-auto"
            >
              <i :class="!letEdit ? 'fas fa-edit' : 'fas fa-history'"></i>
              {{ !letEdit ? "Editar" : "Desfazer" }}
            </b-btn>
            <b-btn variant="primary" @click="save" :disabled="!letEdit">
              <i class="fas fa-save"></i> Salvar
            </b-btn>
            <!-- <b-btn variant="danger">
              <i class="fas fa-ban"></i> Limpar
            </b-btn> -->
          </div>
        </div>
      </b-tab>
      <b-tab></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import EmpresasLib from "../../../libs/EmpresasLib";
export default {
  props: {
    empresa: { type: Object, default: null },
    notaFiscalValue: { type: Object, default: null },
  },
  data() {
    return {
      empresaNfe: null,
      letEdit: false,
      pct: {
        prefix: "% ",
        precision: 2,
        decimal: ",",
        masked: false,
      },
      notafiscal: {
      
          endereco: {
            codigoPais: "1058",
            descricaoPais: "Brasil",
            tipoLogradouro: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            codigoCidade: "",
            estado: "",
            cep: "",
          },
          telefone: {
            ddd: "",
            numero: "",
          },
          incentivoFiscal: false,
          incentivadorCultural: false,
          regimeTributario: null,
          regimeTributarioEspecial: null,
          simplesNacional: false,
          certificado: "",
          nfse: {
            ativo: true,
            tipoContrato: 0,

            config: {
              rps: {
                lote: "",
                numeracao: [],
              },
              prefeitura: {
                login: "",
                senha: "",
              },
              email: {
                envio: false,
              },
              producao: false,
            },
            servicos: [
              {
                codigo: "",
                codigoTributacao: "",
                discriminacao: "",
                cnae: "",
                iss: {
                  tipoTributacao: "",
                  aliquota: 0.0,
                },
              },
            ],
          },
        
        cpfCnpj: "",
        inscricaoMunicipal: "",
        razaoSocial: "",
        nomeFantasia: "",
        email: "",
      },
      campos: [
        { key: "serie", label: "Série", class: "text-center" },
        { key: "numero", label: "Número", class: "text-center" },

        { key: "actions", label: "Ações", class: "text-center" },
      ],

      regimesTributarios: [
        { text: "Nenhum", value: 0 },
        { text: "Simples Nacional", value: 1 },
        { text: "Simples Nacional - Excesso", value: 2 },
        { text: "Normal - Lucro Presumido/Lucro Real", value: 3 },
        { text: "Normal - Lucro Real", value: 4 },
      ],
      regimesTributariosEspeciais: [
        { text: "Sem Regime Tributário Especial", value: 0 },
        { text: "Micro Empresa Municipal", value: 1 },
        { text: "Estimativa", value: 2 },
        { text: "Sociedade de Profissionais", value: 3 },
        { text: "Cooperativa", value: 4 },
        { text: "Microempresário Individual - MEI", value: 5 },
        { text: "Microempresa ou Pequeno Porte - ME EPP", value: 6 },
      ],
      tiposContrato: [
        { text: "Bilhetagem", value: 0 },
        { text: "Ilimitado", value: 1 },
      ],
      tiposTributacao: [
        { text: "Isento de ISS", value: 1 },
        { text: "Imune", value: 2 },
        { text: "Não Incidência no Município", value: 3 },
        { text: "Não Tributável", value: 4 },
        { text: "Retido", value: 5 },
        { text: "Tributável Dentro do Município", value: 6 },
        { text: "Tributável Fora do Município", value: 7 },
        { text: "Tributável Dentro do Município pelo tomador", value: 8 },
      ],
      certificados: [],
    };
  },
  methods: {
    addNumeracao() {
      this.notafiscal.nfse.config.rps.numeracao.push({
        serie: "",
        numero: "",
        _rowStatus: "added",
      });
    },
    addServico() {
      console.log(this.notafiscal.nfse);
      if (!this.notafiscal.nfse.servicos)
        this.$set(this.notafiscal.nfse, "servicos", []);
      this.notafiscal.nfse.servicos.push({
        codigo: "",
        codigoTributacao: "",
        discriminacao: "",
        cnae: "",
        iss: {
          tipoTributacao: "",
          aliquota: 0.0,
          //valor: nfse.valor_iss,
        },
        valor: {
          servico: 0.0,
        },
      });
    },
    editar() {
      if (this.letEdit) {
        this.letEdit = !this.letEdit;
        if (this.notaFiscalValue) {
          this.notafiscal = JSON.parse(JSON.stringify(this.notaFiscalValue));
        }
      } else {
        this.letEdit = true;
      }
    },
    saveNumeracao(item) {
      this.$set(item, "_rowStatus", null);
      const numeracoes = this.notafiscal.nfse.config.rps.numeracao;

      const idx = numeracoes.indexOf(item);
      //console.log('idx', idx);
      if (idx !== -1) delete numeracoes[idx]._rowStatus;

      //delete item._rowStatus
    },
    removeNumeracao(item) {
      const numeracoes = this.notafiscal.nfse.config.rps.numeracao;
      const idx = numeracoes.indexOf(item);
      if (idx !== -1) {
        numeracoes.splice(idx, 1);
      }
    },
    async save() {
      // let modified
      // const empresaPlug = this.empresaNfe.empresa
      // for (const key of Object.keys(empresaPlug)) {

      // }
      let empresaPlug;
      if (this.notafiscal && this.empresa) {
        const cnpjUnformated = this.empresa.cnpj
          .split("")
          .filter((c) => ![".", "-", "/"].includes(c))
          .join("");
        // console.log("CNPJUN", cnpjUnformated);
        empresaPlug = {
          ...this.notafiscal,
          cpfCnpj: cnpjUnformated,
          razaoSocial: this.empresa.razao_social,
          nomeFantasia: this.empresa.fantasia,
          // inscricaoMunicipal: "38497",
          email: this.empresa.emails,
        };
      }
      //console.log("empresa que vai salvar no plug", empresaPlug);
      // empresaPlug;
      let result;
      this.$emit("input", this.notafiscal);
      this.letEdit = false;
      let a = true;
      if (a) {
        try {
          // result = await EmpresasLib.storeEmpresaConfigNfe(empresaPlug);
          // if (result && result.status == 201) {
          //   this.$emit("input", this.notafiscal);
          //   this.letEdit = false;
          // } else {
          //   this.showToast(
          //     "error",
          //     `Erro ao salvar configurações\n${result.data.error.message}`,
          //     5000
          //   );
          // }
        } catch (error) {
          // console.log(error);
        }
      } else {
        try {
          result = await EmpresasLib.udpateEmpresaConfigNfe(
            empresaPlug.cpfCnpj,
            empresaPlug
          );
          if (result && result.status == 204) {
            this.$emit("input", this.notafiscal);
            this.letEdit = false;
            this.showToast(
              "success",
              `Configurações salvas com sucesso!`,
              5000
            );
          }
        } catch (error) {
          console.log(error);
          this.showToast("error", `Erro ao salvar configurações`, 5000);
        }
      }
      console.log("O RESULTADO PORRA  ", result);
    },
    selectEndereco(endereco) {
      console.log(endereco);
      if (endereco) {
        this.notafiscal.endereco.cep = endereco.cep;
        this.notafiscal.endereco.bairro = endereco.bairro;
        this.notafiscal.endereco.estado = endereco.uf;
        this.notafiscal.endereco.numero = endereco.numero;
        this.notafiscal.endereco.logradouro = endereco.logradouro;
        this.notafiscal.endereco.tipoLogradouro = endereco.tipoLogradouro;
        this.notafiscal.endereco.complemento = endereco.complemento;
      }
    },
    selectTelefone(telefone) {
      console.log(telefone);
      if (telefone) {
        //
        this.notafiscal.telefone = telefone;
      }
    },
    async carregaCertificados() {
      try {
        this.certificados = (await EmpresasLib.getCertificadosNFE()).map(
          (certificado) => {
            return { text: certificado.nome, value: certificado.id };
          }
        );
      } catch (error) {
        console.log("erro chegou na função", error);
      }
    },
  },
  async mounted() {
    await this.$nextTick();
    this.$forceUpdate();
    if (this.notaFiscalValue) {
      this.notafiscal = JSON.parse(JSON.stringify(this.notaFiscalValue));
    }
    try {
      if (this.empresa?.cnpj) {
        const cnpjClean = this.empresa
          ? this.empresa?.cnpj
              .split("")
              .filter((c) => ![".", "-", "/"].includes(c))
          : "";
        //console.log("cnpjClean", cnpjClean);
        this.empresaNfe = (
          await EmpresasLib.getEmpresaConfigNfe(cnpjClean)
        ).data;
        await this.carregaCertificados();
      }
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    enderecosDisponiveis() {
      return this.empresa?.enderecos?.map((endereco) => {
        return {
          value: endereco,
          text: `${endereco.cep} - ${endereco.tipoLogradouro} ${endereco.logradouro}, N°${endereco.numero}. ${endereco.cidade} - ${endereco.uf}.`,
        };
      });
    },
    telefonesDisponiveis() {
      return this.empresa?.telefones?.map(({ contato, telefone }) => {
        const ddd = telefone
          .slice(telefone.indexOf("(") + 1, telefone.indexOf(")"))
          .trim();
        const telefoneWithoutDDD = telefone
          .slice(telefone.indexOf(")") + 1)
          .trim()
          .replace("-", "");

        return {
          value: { ddd, telefone: telefoneWithoutDDD },
          text: `${contato ? contato + " - " : ""}  ${telefone}`,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.separator {
  font-size: 14px;
  color: #0a0a0a;

  margin: 32px 0;
  display: flex;

  align-items: center;
  &::before {
    content: "";

    flex: 1;
    height: 1px;
    background: #dfdfdf;
    margin-right: 16px;
  }
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background: #dfdfdf;
    margin-left: 16px;
  }
}
</style>
